<template>
  <div class="py-md-8 py-6">
    <s-loading :padding="5" v-if="fetching" />
    <v-form v-else v-model="formIsValid" ref="cabinetColorForm" @submit.prevent="handleFormSubmit" lazy-validation>
      <v-row>
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 d-inline-block mb-3">
            {{ $t("createOrder.colors.form.cabinetColor.label") }}
          </label>
          <v-radio-group hide-details="auto" class="cabinet-colors" :rules="rules.cabinetColorId" row
                         v-model="form.cabinetColorId">
            <template v-for="cabinetColor in cabinetColors">
              <v-radio active-class="active" class="image-radio" :key="cabinetColor.id" :value="cabinetColor.id">
                <template v-slot:label>
                  <div class="my-2 d-flex flex-column align-center">
                    <img :src="getAttachmentPreviewURL(cabinetColor.image)" />
                    <span class="d-inline-block mt-1 text-subtitle-2">{{ cabinetColor.displayName }}</span>
                  </div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="mt-md-8 mt-6 action-buttons">
        <v-btn
          type="button"
          elevation="0"
          class="mr-4 rounded-0"
          outlined
          @click="goToPreviousStep"
          large
        >
          {{ $t("defaults.back") }}
        </v-btn>
        <v-btn
          type="submit"
          elevation="0"
          color="primary"
          :disabled="!formIsFilled || !formIsValid"
          class="rounded-0"
          large
        >
          {{ $t("defaults.continue") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getAttachmentPreviewURL } from "@/utils/attachment";

export default {
  data() {
    return {
      formIsValid: false,
      fetching: false,
      form: {
        cabinetColorId: null
      },
      rules: {
        cabinetColorId: [
          v => !!v || this.$t("createOrder.colors.form.cabinetColor.rules.required")
        ]
      }
    };
  },
  computed: {
    ...mapState("Colors", {
      cabinetColors: state => state.cabinetColors
    }),
    formIsFilled() {
      return Boolean(this.form.cabinetColorId);
    }
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Colors", ["getCabinetColors", "saveSelectedCabinetColor"]),
    ...mapActions("Steps", ["completeStep"]),
    handleFormSubmit() {
      const isValid = this.$refs.cabinetColorForm.validate();
      if (isValid) {
        this.saveSelectedCabinetColor(this.form.cabinetColorId);
        this.completeStep("colors/cabinet");
        this.$router.push("/design-countertop/visualization");
      }
    },
    goToPreviousStep() {
      this.$router.push("/design-countertop/colors/floor");
    }
  },
  async mounted() {
    if (this.cabinetColors.length === 0) {
      this.fetching = true;
      await this.getCabinetColors();
      this.fetching = false;
    }

    const { selectedCabinetColor } = this.$store.state.Colors;
    if (selectedCabinetColor) {
      this.form.cabinetColorId = selectedCabinetColor.id;
    }
  }
};
</script>
